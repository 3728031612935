<template>
  <div class="wendadetails">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/falvzixun' }"
            >法律咨询主页</el-breadcrumb-item
          >
          <el-breadcrumb-item>咨询详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <!-- 内容 -->
    <div class="typedetailsmain base_w flex">
      <!-- 左竖列 -->
      <div class="common_index_leftwidth">
        <div class="wendasuoyou">
          <div class="zixunitem">
            <!-- 问 -->
            <div class="zixuntop flex">
              <img src="@/assets/img/wen.png" alt="" />
              <span class=""
                >{{info.content}}</span
              >
            </div>
            <!-- 类型 地址 时间 -->
            <div class="typeaddr">
              <div class="flexbox">
                <p class="lei" v-if="info.fenglei">{{info.fenglei}}</p>
                <p class="di" v-if="info.lsLawyer">{{info.lsLawyer.addr}}</p>
              </div>
              <p class="shi"> {{info.createTime | fifterTime}} </p>
            </div>
            <div class="lshf"> <span></span> 律师回复</div>
            <!-- 律师答 -->
            <div class="dabox"  v-if="info.lsFuwuOrderMsgs">
              <div class="flexbox topda"  v-if="info.lsLawyer">
                <img :src="info.lsLawyer.photo" alt=""/>
                <span class="ri5">{{info.lsLawyer.lawyerName}}律师</span>
                <span class="io">{{info.lsLawyer.addr}}</span>
              </div>
              <div class="daitem" v-for="(item,i) in info.lsFuwuOrderMsgs" :key="i">
                <div class="topdaitem flexbox">
                  <img src="@/assets/img/da.png" alt="" />
                  <span class="line1"
                    >{{item.content}}</span
                  >
                </div>
                <p>{{item.createTime | fifterTime}}</p>
              </div>
            </div>
          </div>

          <div class="xswttj">
            <div class="common_title_box">
              <span class="title">相似问题推荐</span>
            </div>
            <wenda ref="newwenda" :paging="false" :type="2" :more="false" :wendalistArr="wentypedalist" @refresh="refresh"></wenda>

            <div class="morewen flexbox" @click.stop="tourl('/typedetails?fenglei=' + info.fenglei)">更多问题 
              <img src="@/assets/img/right.png" alt="">
            </div>
          </div>

        </div>
      </div>
      <!-- 右竖列 -->
      <div class="common_index_rightwidth">
        <!-- 在线法律咨询 -->
        <div class="zxflzx flex_column_center">
          <p class="c1">在线法律咨询</p>
          <p class="c2"><span>1</span>分钟提问，<span>30</span>秒匹配律师</p>
          <div class="imgbox1">
            <img src="@/assets/img/ls.png" alt="" class="img1" />
            <img src="@/assets/img/ls.png" alt="" class="img2" />
            <img src="@/assets/img/ls.png" alt="" class="img3" />
            <p>当前 <span> 2399 </span> 名律师在线</p>
          </div>
          <div class="btnbox" @click.stop="tourl('/toask')">立即提问</div>
        </div>
        <!-- 热门律师 -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">热门律师</span>
            <div class="righttext" @click.stop="tourl('/lvlist')">
              更多
              <img src="@/assets/img/right.png" alt="" class="iconimg" />
            </div>
          </div>
          <hotls :list="hotlawlist"></hotls>
        </div>

        <!-- 法律资讯 -->
        <div class="flzx">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="flzxbox">
            <news :type="2" :list="informationlist"></news>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import wenda  from '@/components/wenda'
import hotls from '@/components/hotls'
import news from '@/components/news'


import resetTime from "@/util/check.js";
import apiUrl from '@/api/main'
export default {
  components: {wenda, hotls, news },
  data() {
    return {
      hotlawlist:[], // 热门律师
      informationlist:[], // 法律资讯
      info:{}, 
      pagination:{
          size: 10, // 每页几条
          currentPage: 1,
          total: 0
        },
        wentypedalist:[], // 问答
        url:'/typedetails',
    }
  },
  computed: {},
  watch: {},
  filters: {
			fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
		},
  mounted() {
    this.gethostlist(); // 热门律师
    this.getinformation(); // 法律资讯
    this.getinfo(); // 咨询详情
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    // 刷新
    refresh(){
      this.gethostlist(); // 热门律师
      this.getinformation(); // 法律资讯
      this.getinfo(); // 咨询详情
      // 跳转回顶部
      document.documentElement.scrollTop = 0;
    },
     // 跳转
     tourl(url) {
        this.$router.push({
          path: url
        })
      },
      // 获取咨询详情
      getinfo() {
        let data = {
          id: this.$route.query.id
        }
        apiUrl.wendainfo(data).then((res) => {
          this.info = res.data;
          this.getwendalist(); // 相似问答列表
        })
      },

      // 获取 热门律师
      gethostlist() {
        let data = {
          pageSize: 3,
          pageNum: 1,
          tuijian: 1,
          orderByColumn: "haopinhlv",
          isAsc: "desc"
        }
        apiUrl.lsLawyerindex(data).then((res) => {
           
            res.rows.forEach((item,i) => {
              if(item.goodsIds){
                item.goodsIdsArr = item.goodsIds.split(',');
              }
            });

            this.hotlawlist = res.rows;
        })
      },
    // 获取法律资讯 
      getinformation(){
        let data = {
          userId:localStorage.getItem('userId'),
          pageSize: 3,
          pageNum: 1,
        }
        apiUrl.lsInformationlist(data).then((res) => {
           this.informationlist = res.rows;
        });
      },

      // 获取相似问题推荐
      getwendalist(){
        let data = {
          pageSize: this.pagination.size,
          pageNum: this.pagination.currentPage,
          fenglei: this.info.fenglei,
        }
        apiUrl.wendalist(data).then((res) => {
          this.wentypedalist = res.rows;
        });
      },

  },
}
</script>
  <style lang='scss' scoped>
.wendadetails {
  padding-bottom: 67px;
  .common_index_leftwidth {
    .wendasuoyou {
      margin-top: 30px;

      .zixunitem {
        margin-bottom: 10px;
        padding-bottom: 47px;
        border-bottom: 2px solid #333;
        .zixuntop {
          img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }
          span {
            width: 790px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .dabox {
          margin-top: 17px;
          .topda {
            img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              margin-right: 12px;
            }
            .ri5{
              font-size: 18px;
              font-weight: 500;
              color: #333333;
              margin-right: 12px;
            }
            .io {
              font-size: 16px;
              color: #777777;
            }
          }
          .daitem {
            margin-left: 50px;
            width: 770px;
            background: #f5f5f5;
            border-radius: 0px 16px 16px 16px;
            margin-bottom: 24px;
            .topdaitem {
              padding: 16px;
              img {
                width: 22px;
                height: 22px;
                margin-right: 12px;
              }
              span {
                display: inline-block;
                width: 658px;
                font-size: 16px;
                color: #333333;
              }
            }
            p {
              text-align: right;
              font-size: 14px;
              color: #777777;
              padding-right: 16px;
            }
          }
        }
        .lshf{
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            margin: 44px 0 27px;
          span{
            display: inline-block;
            width: 8px;
            height: 19px;
            background: #305BFE;
            border-radius: 2px;
            margin-right: 12px;
          }
        }
      }
      .typeaddr {
        margin-top: 16px;
        padding-left: 38px;
        display: flex;
        align-items: center;
        .lei {
          border-radius: 17px;
          border: 1px solid #cccccc;
          padding: 4px 16px;
          font-size: 14px;
          color: #777777;
          margin-right: 20px;
        }
        .di {
          font-size: 14px;
          color: #777777;
          margin-right: 24px;
        }
        .shi {
          font-size: 14px;
          color: #777777;
        }
      }
      .xswttj{
        margin-top: 41px;
      }


    }
  }
  .common_index_rightwidth {
    .zxflzx {
      background: url(../../assets/img/bg8.png) no-repeat;
      background-size: 100% 100%;
      width: 586px;
      height: 272px;
      margin-top: 33px;
      .c1 {
        font-size: 23px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #3d4962;
        line-height: 31px;
        letter-spacing: 2px;
      }
      .c2 {
        font-size: 18px;
        font-weight: 500;
        color: #3d4962;
        margin-top: 23px;
        span {
          color: #ff7900;
        }
      }
      .imgbox1 {
        position: relative;
        width: 400px;
        margin-top: 33px;
        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          position: absolute;
        }
        .img1 {
          top: 0;
          left: 23%;
        }
        .img2 {
          top: 0;
          left: 28%;
        }
        .img3 {
          top: 0;
          left: 33%;
        }
        p {
          position: absolute;
          top: 0;
          left: 42%;
          display: inline-block;
          height: 27px;
          line-height: 27px;
          font-size: 16px;
          color: #60666f;
          span {
            color: #305bfe;
            margin: 0 3px;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      .btnbox {
        width: 140px;
        height: 42px;
        line-height: 42px;
        border-radius: 21px;
        margin-top: 60px;
      }
    }
    // 热门律师
    .rmls {
      margin-top: 33px;
      .common_title_box {
        margin-bottom: 13px;
      }
    }
    // 法律资讯
    .flzx {
      margin-top: 33px;
      .flzxbox {
        // width: 488px;
        width: 586px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 27px;
        margin-top: 13px;
      }
    }
  }
}
</style>